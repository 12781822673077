import styles from "./VideoPlayerButton.module.css";
type propsType = {
  text?: string;
  type: "white" | "purple" | "outline";
  onClick?: () => void;
};

const { btn, whiteBtn, purpleBtn, outlineBtn } = styles;

const VideoPlayerButton = ({ text, onClick, type }: propsType) => {
  return (
      <a 
      className={`${btn} ${type === "purple" ? purpleBtn : type === "outline" ? outlineBtn : whiteBtn}`}
    >      
      <img src={`${process.env.PUBLIC_URL}/icons/${type === "outline" ? 'playTestCampaign' : 'play'}.svg`} alt="Play" />
      {text}
    </a>
    // <a href="https://www.youtube.com/" target="_blank" onClick={onClick} rel="noreferrer"
    //   className={`${btn} ${type === "purple" ? purpleBtn : type === "outline" ? outlineBtn : whiteBtn}`}
    // >      
    //   <img src={`${process.env.PUBLIC_URL}/icons/${type === "outline" ? 'playTestCampaign' : 'play'}.svg`} alt="Play" />
    //   {text}
    // </a>
  );
};

export default VideoPlayerButton;
