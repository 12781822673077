export type campaign = {
  agent: any,
  business: number,
  channel: string,
  clicks: number,
  comments: number,
  created_on: string,
  creation_by: string,
  id: number,
  is_draft: boolean,
  is_test?: boolean,
  message_format: string,
  name: string,
  platform: number,
  reminder_sent: boolean,
  responses: number,
  result: number,
  sent: number,
  type: string,
  feedback:any[]
};

export type campaigns = campaign[];

//state schema
export interface campaignState {
  campaigns: campaigns | [];
}

//action and action types
export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';

interface SaveCampaigns {
  type: typeof SAVE_CAMPAIGN;
  payload: campaigns;
}

export type campaignActionType = SaveCampaigns;
