import { packageData } from "src/services/DataAction/Packages/types";

export type packages = packageData[];

//state schema
export interface packageState {
  packages: packages | [];
}

//action and action types
export const SAVE_PACKAGES = 'SAVE_PACKAGES';

interface SavePackages {
  type: typeof SAVE_PACKAGES;
  payload: packages;
}

export type packageActionType = SavePackages;
