import { Rule } from "antd/lib/form";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";

interface formItemPropsType {
  guide?: string;
  name: string;
  label?: string;
  children: React.ReactNode;
  rules?: Rule[] | undefined;
  className?: string | undefined;
  initialValue?: any;
  style?: React.CSSProperties;
}

const FormItem = ({
  guide,
  name,
  label,
  children,
  rules,
  className,
  initialValue,
  style
}: formItemPropsType) => {
  return (
    <Form.Item
      style={style}
      extra={guide && (<><InfoCircleOutlined style={{marginRight:'.5rem'}}/><p>{guide}</p></>)}
      rules={rules}
      name={name}
      label={label}
      className={className}
      initialValue={initialValue}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
