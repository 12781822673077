import { businesses, businessActionType,SAVE_BUSINESSES, business,SAVE_CURRENT_BUSINESS } from './types';

export function SaveBusinesses(businesses: businesses): businessActionType {
  return {
    type: SAVE_BUSINESSES,
    payload: businesses,
  };
}
export function SaveCurrentBusiness(business: business | null): businessActionType {
  return {
    type: SAVE_CURRENT_BUSINESS,
    payload: business,
  };
}
