import axios from 'axios';

if (window.location.host !== 'localhost:3000') {
   console.clear();
}

export const apiBaseUrl = process.env.REACT_APP_BASE_URL;

const api = axios.create({
   baseURL: apiBaseUrl,
   headers: {
      Authorization: `Bearer ${localStorage.getItem('upperly_token')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
   },
});

export default api;
