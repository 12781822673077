import api from "src/services/AxiosConfig"
import {customerData} from "./types"

export const createCustomer = (businessId : string | number, data : FormData) => {
    return api.post(`/businesses/business/${businessId}/customers/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getAllCustomers = (businessId : string | number) => {
    return api.get(`/businesses/business/${businessId}/customers/`)
}

export const getCustomerCampaignHistory = (customerId : string | number) => {
    return api.get(`/businesses/customer/${customerId}/campaigns/`)
}

export const editCustomer = (businessId : string | number, customerId : string | number, data : customerData) => {
    return api.patch(`/businesses/business/${businessId}/customers/${customerId}/`, data)
}
