// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* whiteBtn, purpleBtn, outlineBtn */
.VideoPlayerButton_btn__nqtQr{
  display: flex;
  color: var(--primary-color) !important;
  font-family: "Ubuntu Medium";
  align-items: center;
  margin-top: 0.5rem;
  width: fit-content;
}

.VideoPlayerButton_btn__nqtQr:hover{
  color: var(--primary-color);
}

.VideoPlayerButton_btn__nqtQr > img{
  cursor: pointer;
}

.VideoPlayerButton_whiteBtn__oLEds, .VideoPlayerButton_purpleBtn__UHqed{
  flex-direction: row;
  border-radius: var(--borderRadius25);
  padding-right: 1rem;
  font-size: 1rem;
}

.VideoPlayerButton_whiteBtn__oLEds > img, .VideoPlayerButton_purpleBtn__UHqed > img{
  margin-right: 0.5rem;
  margin-left: -1px;
}

.VideoPlayerButton_whiteBtn__oLEds{
  background-color: var(--white-color);
}

.VideoPlayerButton_purpleBtn__UHqed {
  border: 1px solid var(--primary-color);
  background-color: transparent;
}

.VideoPlayerButton_outlineBtn__UdaIq{
  flex-direction: column;
}

.VideoPlayerButton_outlineBtn__UdaIq > img{
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/VideoPlayerButton/VideoPlayerButton.module.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,aAAa;EACb,sCAAsC;EACtC,4BAA4B;EAC5B,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,sCAAsC;EACtC,6BAA6B;AAC/B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/* whiteBtn, purpleBtn, outlineBtn */\n.btn{\n  display: flex;\n  color: var(--primary-color) !important;\n  font-family: \"Ubuntu Medium\";\n  align-items: center;\n  margin-top: 0.5rem;\n  width: fit-content;\n}\n\n.btn:hover{\n  color: var(--primary-color);\n}\n\n.btn > img{\n  cursor: pointer;\n}\n\n.whiteBtn, .purpleBtn{\n  flex-direction: row;\n  border-radius: var(--borderRadius25);\n  padding-right: 1rem;\n  font-size: 1rem;\n}\n\n.whiteBtn > img, .purpleBtn > img{\n  margin-right: 0.5rem;\n  margin-left: -1px;\n}\n\n.whiteBtn{\n  background-color: var(--white-color);\n}\n\n.purpleBtn {\n  border: 1px solid var(--primary-color);\n  background-color: transparent;\n}\n\n.outlineBtn{\n  flex-direction: column;\n}\n\n.outlineBtn > img{\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `VideoPlayerButton_btn__nqtQr`,
	"whiteBtn": `VideoPlayerButton_whiteBtn__oLEds`,
	"purpleBtn": `VideoPlayerButton_purpleBtn__UHqed`,
	"outlineBtn": `VideoPlayerButton_outlineBtn__UdaIq`
};
export default ___CSS_LOADER_EXPORT___;
