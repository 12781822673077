import { platforms, platformActionType,SAVE_ALL_PLATFORMS, SAVE_BUSINESS_PLATFORMS, businessPlatforms } from './types';

export function SavePlatforms(platforms: platforms): platformActionType {
  return {
    type: SAVE_ALL_PLATFORMS,
    payload: platforms,
  };
}
export function SaveBusinessPlatforms(platforms: businessPlatforms): platformActionType {
  return {
    type: SAVE_BUSINESS_PLATFORMS,
    payload: platforms,
  };
}
