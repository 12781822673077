import { billingData } from "src/services/DataAction/Billing/types";

export type creditCards = billingData[];

//state schema
export interface creditCardState {
  creditCards: creditCards | [];
}

//action and action types
export const SAVE_CREDIT_CARDS = 'SAVE_CREDIT_CARDS';

interface SaveCreditCards {
  type: typeof SAVE_CREDIT_CARDS;
  payload: creditCards;
}

export type creditCardActionType = SaveCreditCards ;
