import { creditCardState, creditCardActionType, SAVE_CREDIT_CARDS } from './types';

const initialState: creditCardState = {
  creditCards: [],
};

export function CreditCardsReducer(state = initialState, action: creditCardActionType): creditCardState {
  switch (action.type) {
    case SAVE_CREDIT_CARDS:
      return {
        ...state,
        creditCards: action.payload,
      };

    default:
      return state;
  }
}
