import { Breadcrumb } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './BreadCrumb.module.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { imageSrc } from 'src/utils';
const { breadcrumb__container, remainingTitle } = styles;

const BreadCrumb = () => {
  const location = useLocation();
  const path = useLocation().pathname;
  const { campaignId, customerId, agentId } = useParams();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const userPackage = useSelector((state:RootState) => state.user.user)?.user_package;
  const campaigns = useSelector((state:RootState) => state.campaigns.campaigns);
  const customers = useSelector((state:RootState) => state.customers.customers);
  const agents = useSelector((state:RootState) => state.agents.agents);
  const currentBusiness = useSelector((state: RootState) => state.businesses.currentBusiness);
  const campaign_details_name = campaigns.find(campaign => campaign.id === Number(campaignId));
  const customer_details_name = customers.find(customer => customer.id === Number(customerId));
  const agent_details_name = agents.find(agents => agents.id === Number(agentId));

  const campaignsRemaining = (
    <span className={remainingTitle}>
      Campaigns: <b>{campaigns.length}/ <img src={imageSrc('infinity')} alt="" /> </b>
    </span>
  );
  const customersRemaining = (
    <span className={remainingTitle}>
      Customers: <b>{customers?.length}/ <img src={imageSrc('infinity')} alt="" /> </b>
    </span>
  );
  const agentsRemaining = (
    <span className={remainingTitle}>
      Agents: <b>{agents?.length}/ <img src={imageSrc('infinity')} alt="" /> </b>
    </span>
  );

  const breadcrumbNameMap: Record<string, string> = {
    '/campaigns': 'Campaigns',
    [`/campaigns/${campaignId}`]: campaign_details_name?.created_on || 'Campaign details',
    '/business_information': 'Business Information',
    '/platforms': 'Platforms',
    '/customers': 'Customers',
    [`/customers/${customerId}`]: customer_details_name?.first_name ? `${customer_details_name?.first_name} ${customer_details_name?.last_name}` : 'Customer Details',
    '/agents': 'Agents',
    [`/agents/${agentId}`]: agent_details_name?.first_name ? `${agent_details_name?.first_name} ${agent_details_name?.last_name}` : 'Agent Details',
    '/billing': 'Billing',
    '/profile': 'Profile',
    '/new_campaign': 'New Campaign',
  };

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    if (!breadcrumbNameMap[url]) return <></>;
    return (
      <Breadcrumb.Item key={url || index}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/dashboard">Dashboard</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <div className={breadcrumb__container}>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
      {path === '/campaigns' || campaignId
        ? campaignsRemaining
        : path === '/customers' || customerId
        ? customersRemaining
        : (path === '/agents' || agentId) && agentsRemaining}
    </div>
  );
};

export default BreadCrumb;
