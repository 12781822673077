import api from "src/services/AxiosConfig"
import {platformData, suggestedPlatformData} from "./types";


export const getAllPlatforms = () => {
    return api.get(`/businesses/platforms/`);
}

export const getAllBusinessPlatforms = (businessId : string | number) => {
    return api.get(`/businesses/business/${businessId}/platforms/`);
}

export const createPlatform = (businessId : string | number, data : platformData) => {
    return api.post(`/businesses/business/${businessId}/platforms/`, data);
}

export const deletePlatform = (businessId : string | number,platformId:number | string) => {
    return api.delete(`/businesses/business/${businessId}/platforms/${platformId}`);
}

export const updatePlatform = (businessId : string | number,platformId:number | string, data : platformData) => {
    return api.patch(`/businesses/business/${businessId}/platforms/${platformId}/`,data);
}

export const suggestPlatform = (data : suggestedPlatformData) => {
    return api.post(`/businesses/suggested-platform/`, data);
}
