import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import { sideBarItems } from 'src/routes';
import styles from './Sidebar.module.css';
import { Link } from 'react-router-dom';
import { imageSrc } from 'src/utils';
import VideoPlayerButton from '../common/VideoPlayerButton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import PackageTooltip from '../common/PackageTooltip';
import { useState } from 'react';

const { Sider } = Layout;
const { sidebar_container, link_container, sidebar__menu, sidebar__menu_item, sidebar__movie, sidebar__up } = styles;

function Sidebar() {
   const user = useSelector((state: RootState) => state.user.user);
   const [activeStatus, setActiveStatus] = useState<string>();
   const hasPackage = !!user?.user_package?.cap;

   return (
      <Sider
         theme='light'
         className={sidebar_container}
         breakpoint={'lg'}
         collapsedWidth='0'
         style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
         }}>
         <div className={sidebar__up}>
            <ul className={sidebar__menu}>
               {sideBarItems?.length > 0 &&
                  sideBarItems.map((item) => {
                     return (
                        <li className={sidebar__menu_item} key={item.id}>
                           <NavLink
                              to={item.path}
                              key={item.label}
                              style={({ isActive }: any) => ({
                                 background: isActive ? '#edebf7' : 'transparent',
                                 color: isActive ? '#544DA0' : '#555555',
                              })}
                              onClick={(e) => setActiveStatus(item.label)}>
                              <img src={imageSrc(item.label, `sidebar_icons/${activeStatus === item.label ? 'active' : 'deactive'}`)} alt='' />
                              <span>{item.label}</span>
                           </NavLink>
                        </li>
                     );
                  })}
            </ul>

            <PackageTooltip show={!hasPackage} placement='bottom'>
               <div className={link_container}>
                  <Link
                     to='/new_campaign'
                     onClick={(e) => {
                        if (!hasPackage) {
                           e.preventDefault();
                        }
                     }}>
                     <img src={imageSrc('add_blue')} alt='Plus' /> Create a New Campaign
                  </Link>
               </div>
            </PackageTooltip>
         </div>

         <div className={sidebar__movie}>
            <VideoPlayerButton text='Watch tutorials' type='white' />
         </div>
      </Sider>
   );
}

export default Sidebar;
