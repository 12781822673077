export type agent= {
    first_name: string;
    last_name: string;
    email: string;
    business_id: string;
    agentId?: string;
    key: string;
    business?: number,
    creation_by?: string,
    id?: number,
    is_active?: boolean,
};

export type agents = agent[];

//state schema
export interface agentState {
  agents: agents | [];
}

//action and action types
export const SAVE_AGENTS = 'SAVE_AGENTS';

interface SaveAgents {
  type: typeof SAVE_AGENTS;
  payload: agents;
}

export type agentActionType = SaveAgents;
