// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PackageTooltip_business_tooltip__0KvVX .ant-btn {
    width: 45%;
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-size: 100%;
}

.PackageTooltip_business_tooltip__0KvVX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    padding: 10px 1rem;
    font-size: 14px;
    font-weight: bold;
    border-radius: var(--borderRadius5);
    color: var(--primary-color-grey);
}`, "",{"version":3,"sources":["webpack://./src/components/common/PackageTooltip/PackageTooltip.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,mCAAmC;IACnC,gCAAgC;AACpC","sourcesContent":[".business_tooltip :global(.ant-btn) {\n    width: 45%;\n    margin-top: 10px;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    padding: 1rem;\n    font-size: 100%;\n}\n\n.business_tooltip {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    min-width: 200px;\n    padding: 10px 1rem;\n    font-size: 14px;\n    font-weight: bold;\n    border-radius: var(--borderRadius5);\n    color: var(--primary-color-grey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"business_tooltip": `PackageTooltip_business_tooltip__0KvVX`
};
export default ___CSS_LOADER_EXPORT___;
