import { userState, userActionType, SAVE_USER } from './types';

const initialState: userState = {
  user: null,
};

export function UserReducer(state = initialState, action: userActionType): userState {
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
      };

    default:
      return state;
  }
}
