import api from "src/services/AxiosConfig";
import { creditCardData, paymentData } from "./types";

export const getCreditCards = () =>{
    return api.get(`/billings/billing-info/`);
}

export const updateCreditCard = (data:creditCardData) =>{
    return api.patch(`/billings/billing-info/1/`,data);
}

export const bookPackage = (data:paymentData) =>{
    return api.post(`/billings/payment-method/`,data);
}
export const getPaymentHistory = () =>{
    return api.get(`/billings/payments/`);
}
