export type platform= {
  icon: string,
  id: number,
  name: string,
  pdf_guide?: string | null,
  video_guide?: string | null,
};
export type businessPlatform= {
 business:number,
 id:number,
 link:string,
 platform:platform,
 order:number,
};


export type platforms = platform[];
export type businessPlatforms = businessPlatform[];

//state schema
export interface platformState {
  platforms: platforms | [];
  businessPlatforms: businessPlatforms | [];

}

//action and action types
export const SAVE_ALL_PLATFORMS = 'SAVE_ALL_PLATFORMS';
export const SAVE_BUSINESS_PLATFORMS = 'SAVE_BUSINESS_PLATFORMS';

interface SaveAllPlatforms {
  type: typeof SAVE_ALL_PLATFORMS;
  payload: platforms;
}
interface SaveBusinessPlatforms {
  type: typeof SAVE_BUSINESS_PLATFORMS;
  payload: businessPlatforms;
}

export type platformActionType = SaveAllPlatforms | SaveBusinessPlatforms;
