export type customer= {
  business: number,
  creation_by: string,
  email: string,
  first_name: string,
  google_review: boolean,
  id: number,
  is_block: boolean,
  last_name: string,
  mobile: string,
};

export type customers = customer[];

//state schema
export interface customerState {
  customers: customers | [];
}

//action and action types
export const SAVE_CUSTOMERS = 'SAVE_CUSTOMERS';

interface SaveCustomers{
  type: typeof SAVE_CUSTOMERS;
  payload: customers;
}


export type customerActionType = SaveCustomers
