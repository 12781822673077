import React from "react";

const WelcomePage = React.lazy(() => import("../pages/Welcome"));
const Profile = React.lazy(() => import("../pages/Dashboard/Profile"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Platforms = React.lazy(()=> import("../pages/Dashboard/Platforms"));
const Campaigns = React.lazy(() => import("../pages/Dashboard/Campaigns"));
const CampaignDetails = React.lazy(() => import("../pages/Dashboard/Campaigns/CampaignDetails"));
const BusinessInformation = React.lazy(() => import("../pages/Dashboard/BusinessInformation"));
const Customers = React.lazy(() => import("../pages/Dashboard/Customers"));
const Agents = React.lazy(() => import("../pages/Dashboard/Agents"));
const UserHistory = React.lazy(() => import("../pages/Dashboard/UserHistory"));
const Billing = React.lazy(() => import("../pages/Dashboard/Billing"));
const Packages = React.lazy(() => import("../pages/Packages"));
const NewCampaign = React.lazy(() => import("../pages/NewCampaign"));
const TestCampaign = React.lazy(() => import("../pages/TestCampaign"));
const SuccessCampaign = React.lazy(() => import("../pages/SuccessCampaign"));
const SuccessfulPurchase = React.lazy(() => import("../pages/Successfulpurchase"));

export const privetRoutes = [
  { id:0, name: "Welcome", path: "/welcome", component: WelcomePage  , hasSidebar:false , hasHeader:false},
  { id:1, name: "Dashboard", path: "/dashboard", component: Dashboard  , hasSidebar:true , hasHeader:true},
  { id:2, name: "Campaigns", path: "/campaigns", component: Campaigns , hasSidebar:true , hasHeader:true},
  { id:3, name: "Campaign Details", path: "/campaigns/:campaignId", component: CampaignDetails , hasSidebar:true , hasHeader:true},
  { id:4, name: "Business Information", path: "/business_information", component: BusinessInformation , hasSidebar:true , hasHeader:true},
  { id:5, name: "Platforms", path: "/platforms", component: Platforms , hasSidebar:true , hasHeader:true},
  { id:6, name: "Customers", path: "/customers", component: Customers , hasSidebar:true , hasHeader:true},
  { id:7, name: "Agents", path: "/agents", component: Agents , hasSidebar:true , hasHeader:true},
  { id:8, name: "Customer Details", path: "/customers/:customerId", component: UserHistory , hasSidebar:true , hasHeader:true},
  { id:9, name: "Agent Details", path: "/agents/:agentId", component: UserHistory , hasSidebar:true , hasHeader:true},
  { id:10, name: "Billing", path: "/billing", component: Billing , hasSidebar:true , hasHeader:true},
  { id:11, name: "Profile", path: "/profile", component: Profile , hasSidebar:true , hasHeader:true},
  { id:12, name: "Packages", path: "/packages", component: Packages , hasSidebar:false , hasHeader:true},
  { id:13, name: "New Campaign", path: "/new_campaign", component: NewCampaign , hasSidebar:false , hasHeader:true},
  { id:14, name: "Test Campaign", path: "test_campaign", component: TestCampaign , hasSidebar:false , hasHeader:true},
  { id:15, name: "Success!", path: "/successful_campaign", component: SuccessCampaign , hasSidebar:false , hasHeader:false},
  { id:16, name: "Success!", path: "/successful_purchase", component: SuccessfulPurchase , hasSidebar:false , hasHeader:false},
];

export const sideBarItems = [
  {
    label: 'Dashboard',
    id: '1',
    path: `/dashboard`,
  },
  {
    label: 'Campaigns',
    id: '2',
    path: `/campaigns`,
  },
  {
    label: 'Business Information',
    id: '3',
    path: `/business_information`,
  },
  {
    label: 'Platforms',
    id: '4',
    path: `/platforms`,
  },
  {
    label: 'Customers',
    id: '5',
    path: `/customers`,
  },
  {
    label: 'Agents',
    id: '6',
    path: `/agents`,
  },
]
