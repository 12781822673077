import api from "src/services/AxiosConfig";
import {userProfileData} from "./types";

export const getUserProfile = () => {
    return api.get('/accounts/profile/')
}

export const updateUserProfile = (data : userProfileData) => {
    return api.patch('/accounts/profile/', data)
}
