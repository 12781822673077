import api from "src/services/AxiosConfig"
import {agentData} from "./types"


export const getAllAgents = (businessId : string | number) => {
    return api.get(`/businesses/business/${businessId}/agents/`)
}

export const getAgentCampaignHistory = (agentId : string | number) => {
    return api.get(`/businesses/agent/${agentId}/campaigns/`)
}

export const createAgent = (businessId : string | number, data : agentData) => {
    return api.post(`/businesses/business/${businessId}/agents/`, data)
}

export const editAgent = (businessId : string | number, data : any) => {
    return api.patch(`/businesses/business/${businessId}/agents/${
        data.agentId
    }/`, data)
}
