import { FunctionComponent, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Row, Col, Typography, message } from 'antd';
import { CustomButton, CustomLoading } from 'src/components';
import styles from './Login.module.css';
import { imageSrc } from 'src/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginOutlined } from '@ant-design/icons';
import { getAllBusinesses } from 'src/services/DataAction/Businesses';

const { page__container, page__vector, page__header, header, header__title, header__description, login_logo } = styles;

export const Login: FunctionComponent = () => {
   const { loginWithPopup, getAccessTokenSilently } = useAuth0();
   const hasToken = !!localStorage.getItem('upperly_token');
   const [loading, setLoading] = useState(false);

   const redirectToDashboard = async () => {
      setLoading(true);
      await getAllBusinesses().then((res) => {
         const businesses = res?.data?.results;
         if (businesses?.length > 0) {
            window.location.href = '/dashboard';
         } else if (businesses?.length === 0) {
            window.location.href = '/welcome';
         }
      });
      setLoading(false);
   };
   useEffect(() => {
      if (hasToken) {
         redirectToDashboard();
      }
   }, [hasToken]);

   const handleLogin = async () => {
      setLoading(true);
      await loginWithPopup()
         .then(() => {
            getAccessTokenSilently().then((accessToken) => {
               localStorage.setItem('upperly_token', accessToken);
               localStorage.setItem('showTutorial', JSON.stringify(true));
               window.location.reload();
               redirectToDashboard();
            });
         })
         .catch(() => {
            message.error({ content: 'Something went wrong, try again later.' });
            setLoading(false);
         });
   };

   return (
      <div className='responsive__container' style={{ background: 'white' }}>
         {loading && <CustomLoading animateLoading />}
         <Row className={page__container} justify='center'>
            <Col xl={8} md={12} xs={24} className={page__header}>
               <div className={login_logo}>
                  <img style={{ height: '120px' }} src={imageSrc('upperly_logo_blue')} alt='Upperly' />
               </div>
               <div className={header}>
                  <Typography className={header__title}>Log in now and Start Maximizing Your Business!</Typography>
                  <Typography className={header__description}>Turn happy customers into five stars, stand out from the competition and collect user feedback.</Typography>
               </div>
               <CustomButton
                  disabled={hasToken || loading}
                  background='white'
                  style={{
                     width: '100%',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     marginBottom: '1rem',
                     boxShadow: '0px 0px 20px rgba(84, 77, 160, 0.25)',
                  }}
                  onClick={handleLogin}>
                  <LoginOutlined style={{ fontSize: '120%', marginRight: '0.5rem' }} />
                  <strong>Login</strong>
               </CustomButton>

               <p>Start creating a new business and test campaign!</p>
            </Col>
            <Col xl={16} md={12} xs={0} className={page__vector}>
               <img src={`${process.env.PUBLIC_URL}/static/panel/img/vector_login_page.svg`} alt='Upperly' />
            </Col>
         </Row>
      </div>
   );
};
export default Login;
