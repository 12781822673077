import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { user } from 'src/redux/users/types';
import styles from './ProfileMenu.module.css';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { imageSrc } from 'src/utils';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const {
  menu__container,
  menu_title__container,
  menu_item,
  logout_btn,
  profileMenuDropDown,
  profile_img,
  profile_default_img,
  profile_container,
  access_Limited,
  noPackage,
} = styles;

const clearData = () => {
  localStorage.clear();
  sessionStorage.clear();
};

function ProfileMenu() {
  const { logout } = useAuth0();
  const userInfo = useSelector((state: RootState) => state.user.user);
  const hasPackage = !!userInfo?.user_package?.cap;

  const handleLogout = () => {
    clearData();
    logout();
  };

  const menuItems: any = useMemo(
    () => (
      <Menu
        className={menu__container}
        items={[
          {
            key: '1',
            label: (
              <div className={menu_title__container}>
                <h4>
                  {userInfo?.first_name} {userInfo?.last_name}
                </h4>
                <span>{userInfo?.email}</span>
              </div>
            ),
          },
          {
            key: '2',
            label: (
              <Link
                to="/profile"
                className={menu_item}
              >
                {' '}
                Account Information
              </Link>
            ),
          },
          {
            key: '3',
            label: (
              <Link
                to="/packages"
                className={menu_item}
              >
                Packages
              </Link>
            ),
          },
          {
            key: '4',
            label: (
              <Link
                to="/billing"
                className={menu_item}
              >
                {' '}
                Billing
              </Link>
            ),
          },

          {
            key: '5',
            label: (
              <Link
                to="/billing"
                className={menu_item}
              >
                Payment History
              </Link>
            ),
          },

          {
            key: '6',
            label: (
              <button
                onClick={handleLogout}
                className={logout_btn}
              >
                {' '}
                Logout
              </button>
            ),
          },
        ]}
      />
    ),
    [userInfo]
  );

  return (
    <>
      {!hasPackage && (
        <div className={access_Limited}>
          <p>Your access is limited</p>
          <b />
          <Link to="/packages">Buy a package</Link>
        </div>
      )}
      <Dropdown
        overlay={menuItems}
        className={`${profileMenuDropDown} ${!hasPackage ? noPackage : ''}`}
        trigger={['click']}
      >
        <div className={profile_container}>
          {userInfo?.avatar ? (
            <img
              referrerPolicy="no-referrer"
              src={userInfo?.avatar}
              alt={userInfo?.first_name}
              className={profile_img}
            />
          ) : (
            <div className={profile_default_img}>
              <img
                src={imageSrc('avatar', 'dashboard_icons')}
                alt=""
              />
            </div>
          )}
          <DownOutlined />
        </div>
      </Dropdown>
    </>
  );
}

export default ProfileMenu;
