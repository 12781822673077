import { agentState, agentActionType, SAVE_AGENTS } from './types';

const initialState: agentState = {
  agents: [],
};

export function AgentsReducer(state = initialState, action: agentActionType): agentState {
  switch (action.type) {
    case SAVE_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };

    default:
      return state;
  }
}
