import { businessState, businessActionType, SAVE_BUSINESSES, SAVE_CURRENT_BUSINESS } from './types';

const initialState: businessState = {
  businesses: [],
  currentBusiness:null
};

export function BusinessesReducer(state = initialState, action: businessActionType): businessState {
  switch (action.type) {
    case SAVE_BUSINESSES:
      return {
        ...state,
        businesses: action.payload,
      };

    case SAVE_CURRENT_BUSINESS:
      return {
        ...state,
        currentBusiness: action.payload,
      };

    default:
      return state;
  }
}
