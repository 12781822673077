import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { imageSrc } from 'src/utils';
import styles from './Header.module.css';
import ProfileMenu from './components/ProfileMenu';
import BusinessSelection from './components/BusinessSelection';
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

const { headerContainer, mobile_menu_btn, headerContainer_left, business_container, business_container_icon, logout_btn } = styles;

const { Header } = Layout;

const clearData = () => {
   localStorage.clear();
   sessionStorage.clear();
};

function DashboardHeader({ setMobileMenu }: { setMobileMenu: React.Dispatch<React.SetStateAction<boolean>> }) {
   const businesses = useSelector((state: RootState) => state.businesses.businesses);
   const currentBusiness = useSelector((state: RootState) => state.businesses.currentBusiness);
   const isTestCampaign = window.location.pathname === '/test_campaign';
   const { logout } = useAuth0();
   const handleLogout = () => {
      clearData();
      logout();
   };

   return (
      <Header className={headerContainer} style={{ position: 'sticky', top: 0, zIndex: 3, width: '100%' }}>
         <div className={headerContainer_left}>
            <div>
               <img className={mobile_menu_btn} src={imageSrc('menu', undefined, 'svg')} alt='menu' onClick={() => setMobileMenu(true)} />
               <img style={{ height: '40px' }} src={imageSrc('upperly_logo')} alt='Upperly' />
            </div>

            {!isTestCampaign && (
               <>
                  <div className={business_container}>
                     <div className={business_container_icon} onClick={() => (window.location.href = `/dashboard`)}>
                        <img src={imageSrc('home', 'dashboard_icons')} alt='' />
                     </div>
                     {businesses.length > 0 && <BusinessSelection businesses={businesses} currentBusiness={currentBusiness} />}
                  </div>
                  {<ProfileMenu />}
               </>
            )}
            {isTestCampaign && (
               <div className={logout_btn} onClick={handleLogout}>
                  <LogoutOutlined />
                  Logout
               </div>
            )}
         </div>
      </Header>
   );
}

export default DashboardHeader;
