import { customerState, customerActionType, SAVE_CUSTOMERS } from './types';

const initialState: customerState = {
  customers: [],
};

export function CustomersReducer(state = initialState, action: customerActionType): customerState {
  switch (action.type) {
    case SAVE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };

    default:
      return state;
  }
}
