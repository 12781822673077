export const platformFilters = [
    {
        text: 'Google',
        value: 'google',
    },
    {
        text: 'Facebook',
        value: 'facebook',
    },
    {
        text: 'Yelp',
        value: 'yelp',
    },
    {
        text: 'Better Business Bureau',
        value: 'Better Business Bureau',
    },
    {
        text: 'Tripadvisor',
        value: 'Tripadvisor',
    },
];

export const messageFormatFilters = [
    {
        text: 'NPS',
        value: 'nps',
    },
    {
        text: 'YES/NO',
        value: 'yes/no',
    },
    {
        text: 'Direct',
        value: 'direct',
    },
];

export const statusFilters = [
    {
      text: 'Delivered',
      value: 'DELIVERED',
    },
    {
      text: 'Sending',
      value: 'SENDING',
    },
    {
      text: 'In Queue',
      value: 'IN_QUEUE',
    },
    {
      text: 'Customer Failed',
      value: 'CUSTOMER_FAILURE',
    },
    {
      text: 'Server Failed',
      value: 'SERVER_FAILURE',
    },
];

export const npsFilters = [
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: '4',
      value: '4',
    },
    {
      text: '5',
      value: '5',
    },
    {
      text: '6',
      value: '6',
    },
    {
      text: '7',
      value: '7',
    },
    {
      text: '8',
      value: '8',
    },
    {
      text: '9',
      value: '9',
    },
    {
      text: '10',
      value: '10',
    },
  ];


export const yesOrNoFilters = [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
];

export const imageSrc = (name: string, folder?: string ,format:'svg' | 'png' | 'jpg' | 'jpeg' = 'svg') =>{
    if(folder){
        return `${process.env.PUBLIC_URL}/static/panel/img/${folder}/${name}.${format}`
    }else{
        return `${process.env.PUBLIC_URL}/static/panel/img/${name}.${format}`
    }
};

export function dummyRequest({ onSuccess }: any) {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
}