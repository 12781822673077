import { CloudUploadOutlined } from '@ant-design/icons';
import { Row, message } from 'antd';
import { useEffect, useState } from 'react'
import { useCSVReader } from 'react-papaparse';
import { imageSrc } from 'src/utils';
import styles from './CsvFileReader.module.css';

interface CsvFileReaderPropsType {
  onAdd:(csvData:string[][]) => void,
  disabled?:boolean,
}

const {
  progressBarBackgroundColor,
  uploadFile,
  upload_btn,
  upload_btn_add,
  upload_btn_remove,
  upload_place,
  csvReader_container
} = styles;


function CsvFileReader({onAdd,disabled}:CsvFileReaderPropsType) {
  const { CSVReader } = useCSVReader();
  const [csvFile, setCsvFile] = useState<any>(null);

  let removeProps: any = null;
  useEffect(() => {
    if (csvFile?.data.length - 1 >= 80) {
      message.warning({content:'Uploaded file has more than 80 customers, Please shrink it down to 80.',});
    }
  }, [csvFile]);

  return (
    <>
    <CSVReader onUploadAccepted={(results:any) =>{setCsvFile(results)}}>
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => {
        removeProps = getRemoveFileProps();
          return (
            <>
              {csvFile === null ? (
                <div {...getRootProps()} className={csvReader_container}>
                  <div className={upload_place}>
                    <CloudUploadOutlined />
                    <p>
                      Drag & drop the customers file (CSV or Excel) Or {' '}
                      <span>Select file</span>
                    </p>
                  </div>
                </div>
              ) : (
                <div
                className={acceptedFile}
                {...getRootProps()}
                >
                  <Row className={uploadFile}>
                    <div>
                      <img
                        src={imageSrc('numberOfCustomers','campaign_icons')}
                          alt="Icon"
                        style={{ marginRight: '0.5rem' }}
                      />
                      <strong>
                        {acceptedFile && acceptedFile.name} &nbsp;
                      </strong>
                      {csvFile?.data.length - 1} &nbsp;customers found in this file
                    </div>
                  </Row>
                  <ProgressBar className={progressBarBackgroundColor} />
                </div>
              )}
            </>
          );
        }}
    </CSVReader>
    <div className="button-container">
    {csvFile !== null && !disabled && (
      <div className={upload_btn}>
        <button
          disabled={csvFile?.data.length - 1 >= 80}
          className={upload_btn_add}
          onClick={() => {
            onAdd(csvFile?.data);
            setCsvFile(null);
          }}
          >
          <img
            src={imageSrc('addToTable','campaign_icons')}
            alt="Icon"
            style={{ marginRight: '0.5rem' }}
            />
          Add to table
        </button>
        <button
          {...removeProps}
          onClick={() => {
            setCsvFile(null);
          }}
          className={upload_btn_remove}
          >
          <img
            src={imageSrc('remove','campaign_icons')}
            alt="Icon"
            style={{ marginRight: '0.5rem' }}
            />
          Remove
        </button>
      </div>
    )}
    </div>
    </>
  )
}

export default CsvFileReader