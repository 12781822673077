// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BreadCrumb_breadcrumb__container__GwObA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  padding-bottom: 0.5rem;
  
}

.BreadCrumb_breadcrumb__container__GwObA ol li{
  font-size: 21px;
  line-height: 23px;
}

.BreadCrumb_remainingTitle__oWYKA{
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/BreadCrumb/BreadCrumb.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,sBAAsB;;AAExB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".breadcrumb__container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n  border-bottom: 1px solid #dcdcdc;\n  position: relative;\n  padding-bottom: 0.5rem;\n  \n}\n\n.breadcrumb__container ol li{\n  font-size: 21px;\n  line-height: 23px;\n}\n\n.remainingTitle{\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb__container": `BreadCrumb_breadcrumb__container__GwObA`,
	"remainingTitle": `BreadCrumb_remainingTitle__oWYKA`
};
export default ___CSS_LOADER_EXPORT___;
