import { Form, Steps, message } from 'antd';
import styles from './CustomSteps.module.css';
import { CustomButton, VideoPlayerButton } from 'src/components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useStepsForm } from 'sunflower-antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useEffect } from 'react';

const {
  steps,
  steps_section,
  steps__container,
  step_content__container,
  steps_action_buttons,
  step_content,
  step_contentIsTestCampaign,
  tutorial_container,
  testCampaign_note,
} = styles;

interface propsType {
  stepItems: {
    title: string;
    description: string;
    icon: JSX.Element;
    content: JSX.Element;
  }[];
  getValues: (values: any, currentStep: number, GoNext: () => void) => void;
  submitActionName: string;
  loading?: boolean;
}
function CustomSteps({
  stepItems,
  getValues,
  submitActionName,
  loading,
}: propsType) {
  const { current, gotoStep, formProps, submit } = useStepsForm({
    submit: (values) => {
      return values;
    },
  });
  const {state} = useLocation();

  useEffect(() =>{
    if(state){
      formProps.form.setFieldsValue({
        name:state.name,
        category:state.category,
        sender_name:state.sender_name,
        website:state.website,
        phone:state.phone,
        contact_name:state.contact_name,
      });
      message.warning({content:'Please complete your test campaign steps.',duration:3});
    }
  },[]);

  const submitAndGetValues = async () => await submit().then((values) => {
    getValues(values, current, GoNext);
    return values;
  }) 

  const user = useSelector((state: RootState) => state.user.user);
  const isLastStep = current === stepItems.length - 1;
  const path = useLocation().pathname;
  const isTestCampaign = path === '/test_campaign';

  const checkRequirements = (values: any) => {
    if (isTestCampaign) {
      if (current === 0 || (current === 1)) {
        // business information and platforms steps have required fields which is handled by form themselves and we do not need to check requirements here.
        return true;
      }
      return current === 2 && values?.messageFormat;
    } else {
      if (current === 2) {
        //agent step
        return true;
      } else {
        return (
          (current === 0 && values?.platform) ||
          (current === 1 && values?.messageFormat) ||
          (current === 3 && values?.customersFile)
        );
        // (current === 2 && values?.agent) => agent step is not required so i just comment it here for now.
      }
    }
  };

  const GoNext = () => {
    if (current === stepItems.length - 1) return;
    gotoStep(current + 1);
  };

  const GetBack = () => {
    if (current === 0) return;
    gotoStep(current - 1);
  };

  return (
    <div className={steps__container}>
      <div className={steps_section}>
        <Steps
          onChange={(step) => {
            if(isTestCampaign) return;
            submit().then((values:any) =>{
              const valid = (step === 0 && values?.platform) || (step === 1 && values?.messageFormat) || (step === 3 && values?.customersFile) || (step === 2 && values?.platform && values?.messageFormat);
              if(valid){
                gotoStep(step);
              }
            });
          }}
          className={steps}
          current={current}
          direction="vertical"
          items={stepItems}
        />
        {/* <div className={tutorial_container}>
          <h3>Need help?</h3>
          <VideoPlayerButton
            text="Watch a short tutorial"
            type="outline"
          />
        </div> */}
        {/* <a href="https://www.youtube.com/">
          <VideoPlayerButton text="Watch a short tutorial" type="purple" />
        </a> */}
      </div>

      <div className={step_content__container}>
        <div className={steps_action_buttons}>
          {
            <CustomButton
              style={{ marginRight: 'auto' }}
              background="white"
              onClick={() => {
                if (current === 0) {
                  window.history.back();
                } else {
                  GetBack();
                }
              }}
            >
              <LeftOutlined /> Back
            </CustomButton>
          }

          {!isLastStep && (
            <CustomButton
              style={{ marginLeft: 'auto' }}
              background="blue"
              onClick={() => {
                submitAndGetValues().then((res) => {
                  if (checkRequirements(res)) {
                    if (
                      (current === 0 && isTestCampaign) ||
                      (current === 1 && isTestCampaign) ||
                      (current === 3 && !isTestCampaign)
                    )
                      return;
                    GoNext();
                  } else {
                    message.error({ content: 'This step is required.' });
                  }
                });
              }}
            >
              Next <RightOutlined />
            </CustomButton>
          )}
          {isTestCampaign && isLastStep && (
            <span className={testCampaign_note}>
              The test email will be sent to <b>{user?.email}</b>
            </span>
          )}
          {isLastStep && (
            <Form.Item style={{ marginBottom: '0' }}>
              <CustomButton
                loading={loading}
                disabled={loading}
                htmlType="submit"
                style={{ marginLeft: 'auto' }}
                background="blue"
                onClick={() => submitAndGetValues()}
              >
                {submitActionName}
              </CustomButton>
            </Form.Item>
          )}
        </div>

        <Form {...formProps}>
          <div
            className={`${step_content} ${
              isTestCampaign && step_contentIsTestCampaign
            }`}
          >
            {stepItems[current].content}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CustomSteps;
