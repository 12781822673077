import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomLoading } from 'src/components/index';
import DashboardLayout from 'src/components/common/Layouts/DashboardLayout';
import { getAllBusinesses } from 'src/services/DataAction/Businesses';

function AppRoute({ routeName, hasSidebar, hasHeader }) {
   const path = window.location.pathname;
   const hasToken = !!localStorage.getItem('upperly_token');
   const Navigate = useNavigate();
   const businesses = useSelector((state) => state.businesses.businesses);
   useEffect(() => {
      if (!hasToken) {
         Navigate('/login');
      } else if (path === '/') {
         Navigate('/dashboard');
      }

      if (businesses?.length === 0 && hasToken) {
         getAllBusinesses().then((res) => {
            const businesses = res?.data?.results;
            if (businesses?.length === 0 && path !== '/welcome' && path !== '/test_campaign') {
               Navigate('/welcome');
            }
         });
      }
   });

   return (
      <DashboardLayout routeName={routeName} hasSidebar={hasSidebar} hasHeader={hasHeader}>
         <React.Suspense fallback={<CustomLoading />}>
            <Outlet />
         </React.Suspense>
      </DashboardLayout>
   );
}

export default AppRoute;
