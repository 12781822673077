import styles from "./Error404.module.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { imageSrc } from "src/utils";
import CustomButton from "src/components/common/Button";

const { error, error__text, error__image } = styles;

const Error404 = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!Cookies.get('access_token');

  return (
    <div className="responsive__container">
      <div className={error}>
        <div className={error__text}>
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>
            Looks like the page you're looking for isn't working right now.
            Please check the URL, try hitting refresh or check it out later.
          </p>

          <CustomButton
            style={{marginTop: "1rem"}}
            text={isAuthenticated ? "Go Home" : "Login"}
            size="large"
            background="blue"
            onClick={() => {
              isAuthenticated ? navigate(`/dashboard`) : navigate("/login")
            }}
          />
        </div>

        <div className={error__image}>
          <img src={imageSrc("imageError404")} alt="Error 404" />
        </div>
      </div>
    </div>
  );
};

export default Error404;
