import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from 'src/pages/Login';
import Error404 from 'pages/Error404';
import { privetRoutes } from './routes';
import AppRoute from './routes/AppRoute';

function App() {
   return (
      <BrowserRouter>
         <Routes>
            {privetRoutes.map((route, key) => (
               <Route key={key + route.path} path='/' element={<AppRoute hasHeader={route.hasHeader} hasSidebar={route.hasSidebar} routeName={route.name} />}>
                  <Route path={route.path} element={<route.component />} />
               </Route>
            ))}

            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Error404 />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
