import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { combineReducers } from '@reduxjs/toolkit'

import {UserReducer} from './users/reducer';
import {PackagesReducer} from './packages/reducer';
import {PlatformsReducer} from './platforms/reducer';
import {CampaignsReducer} from './campaigns/reducer';
import {BusinessesReducer} from './business/reducer';
import {AgentsReducer} from './agents/reducer';
import {CustomersReducer} from './customers/reducer';

import storage from 'redux-persist/lib/storage'

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { CreditCardsReducer } from './billing/reducer';

const persistConfig = {
  key: 'root',
  storage: storage
}

export const rootReducers = combineReducers({
    user: UserReducer,
    packages: PackagesReducer,
    businesses: BusinessesReducer,
    platforms: PlatformsReducer,
    campaigns: CampaignsReducer,
    customers: CustomersReducer,
    agents: AgentsReducer,
    creditCards: CreditCardsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export default store