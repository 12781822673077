import { campaignState, campaignActionType, SAVE_CAMPAIGN } from './types';

const initialState: campaignState = {
  campaigns: [],
};

export function CampaignsReducer(state = initialState, action: campaignActionType): campaignState {
  switch (action.type) {
    case SAVE_CAMPAIGN:
      return {
        ...state,
        campaigns: action.payload,
      };

    default:
      return state;
  }
}
