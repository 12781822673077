import { Spin } from "antd";
import styles from "./Loading.module.css";
import { imageSrc } from 'src/utils';

const {
  loadingBox,
  loading,
  logo,
  loader,
  loaderStar,
  star1,
  star2,
  star3,
  star4,
  star5,
  star6,
  miniStars,
  miniStars1,
  miniStars2,
  miniStars3,
} = styles;

type propsType = {
  animateLoading?: boolean;
};

const CustomLoading = ({ animateLoading = false }: propsType) => {
  return (
    <>
      {animateLoading ? (
        <div className={loading}>
          <img src={imageSrc("upperly_logo")} alt="" className={logo} />
          <div className={loader}>
            <img
              src={imageSrc("star1")}
              alt=""
              className={`${loaderStar} ${star1}`}
            />
            <img
              src={imageSrc("star2")}
              alt=""
              className={`${loaderStar} ${star2}`}
            />
            <img
              src={imageSrc("star3")}
              alt=""
              className={`${loaderStar} ${star3}`}
            />
            <img
              src={imageSrc("star4")}
              alt=""
              className={`${loaderStar} ${star4}`}
            />
            <img
              src={imageSrc("star5")}
              alt=""
              className={`${loaderStar} ${star5}`}
            />
            <img
              src={imageSrc("star6")}
              alt=""
              className={`${loaderStar} ${star6}`}
            />
          </div>

          <div className={miniStars}>
            <div className={miniStars1}></div>
            <div className={miniStars2}></div>
            <div className={miniStars3}></div>
          </div>
        </div>
      ) : (
        <div className={loadingBox}>
          <Spin />
        </div>
      )}
    </>
  );
};

export default CustomLoading;
