export type business= {
  id: number;
  name: string;
  sender_name: string;
  feedback_threshold: number;
  created_on: string;
  plan: number;
  category: number;
  reserved_plan: number;
  expired_on: string;
  is_subscribed: true;
  trial_expired_on: string;
  trial_is_active: true;
  plan_after_trial: number;
  request_trial: false;
  website: string;
  phone: string;
  contact_name: string;
  start_term: string;
  is_basic: string;
  nps: string;
  customer_remaining: string;
  agent_remaining: string;
  weekly_nps: string;
  monthly_nps: string;
  is_active: boolean;
};

export type businesses = business[];

//state schema
export interface businessState {
  businesses: businesses | [];
  currentBusiness: business | null;
}

//action and action types
export const SAVE_BUSINESSES = 'SAVE_BUSINESSES';
export const SAVE_CURRENT_BUSINESS = 'SAVE_CURRENT_BUSINESS';

interface SaveBusinesses {
  type: typeof SAVE_BUSINESSES;
  payload: businesses;
}
interface SaveCurrentBusiness {
  type: typeof SAVE_CURRENT_BUSINESS;
  payload: business | null;
}

export type businessActionType = SaveBusinesses | SaveCurrentBusiness;
