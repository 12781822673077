import { user_packageType } from "src/services/DataAction/User/types";

export type user = {
  avatar: string,
  email: string,
  first_name: string,
  is_tester: boolean,
  last_name: string,
  mobile: string,
  timezone: string,
  trial_is_used: boolean,
  user: number,
  user_package?: user_packageType;
};


//state schema
export interface userState {
  user: user | null;
}

//action and action types
export const SAVE_USER = 'SAVE_USER';

interface SaveUser {
  type: typeof SAVE_USER;
  payload: user;
}

export type userActionType = SaveUser;
