import api from "src/services/AxiosConfig"
import {campaignData} from './types'


export const createTestBusinessCampaign = (data : campaignData) => {
    return api.post(`/businesses/business/${
        data.business_id
    }/campaigns/send-test/`, data)
}

export const createBusinessCampaign = (businessId : string | number, data : campaignData) => {
    return api.post(`/businesses/business/${businessId}/campaigns/`, data)
}

export const getAllBusinessCampaigns = (businessId : string | number) => {
    return api.get(`/businesses/business/${businessId}/campaigns/`)
}

export const getBusinessCampaign = (businessId : string | number, campaignId : string | number) => {
    return api.get(`/businesses/business/${businessId}/campaigns/${campaignId}/`)
}

export const getBusinessCampaignFeedback = (businessId : string | number, campaignId : string | number) => {
    return api.get(`/businesses/business/${businessId}/campaigns/${campaignId}/feedback`)
}

export const unDraftBusinessCampaign = (businessId : string | number, campaignId : string | number) => {
    return api.patch(`/businesses/business/${businessId}/campaigns/${campaignId}/`, {is_draft: false})
}
export const editBusinessCampaign = (businessId : string | number, campaignId : string | number, data : campaignData) => {
    return api.patch(`/businesses/business/${businessId}/campaigns/${campaignId}/`, data)
}

export const deleteBusinessCampaign = (businessId : string | number, campaignId : string | number) => {
    return api.delete(`/businesses/business/${businessId}/campaigns/${campaignId}/`)
}
