import { Button, ButtonProps } from 'antd';
import styles from './CustomButton.module.css';
import { DeleteOutlined } from '@ant-design/icons';
type propsType = {
  text?: string;
  background?: 'blue' | 'white' | 'red' | 'transparent' | 'green';
  deleteBtn?: boolean;
  testId?: string;
  disabled?: boolean;
  loading?: boolean;
};

const {
  blue__background,
  white__background,
  red__background,
  btn,
  small_btn,
  large_btn,
  transparent__background,
  delete_btn,
  green__background,
} = styles;

const CustomButton = ({
  text,
  onClick,
  background,
  type = 'default',
  size = 'middle',
  shape = 'default',
  htmlType = 'button',
  icon,
  disabled = false,
  deleteBtn = false,
  loading = false,
  testId,
  style,
  children,
  className
}: propsType & ButtonProps) => {
  return (
    <Button
      loading={loading}
      style={style}
      data-testid={testId}
      disabled={disabled}
      htmlType={htmlType}
      shape={shape}
      onClick={onClick}
      type={type}
      className={`${btn} ${className ? className : ''} ${
        background === 'white'
          ? white__background
          : background === 'blue'
          ? blue__background
          : background === 'red'
          ? red__background
          : background === 'green'
          ? green__background
          : transparent__background
      } 
      ${size === 'small' ? small_btn : size === 'large' && large_btn}
      ${deleteBtn ? delete_btn : ''}
      `}
      size={size}
      icon={
        deleteBtn ? (
          <DeleteOutlined />
        ) : (
          icon
        )
      }
    >
      {text || children}
    </Button>
  );
};
export default CustomButton;
