import { Tooltip, TooltipProps } from "antd";
import { Link } from "react-router-dom";
import CustomButton from "../Button";
import styles from "./PackageTooltip.module.css";

const { business_tooltip } = styles;

type PackageTooltipPropsType = {
  children: React.ReactNode;
  message?:string;
  show:boolean;
};

const PackageTooltip = ({ children, placement ,trigger='hover',message,show}: PackageTooltipPropsType & TooltipProps ) => {

  if(!show) {
    return(
      <>
       {children}
      </>
    )
  }

  return (
    <Tooltip
      trigger={trigger}
      arrowPointAtCenter
      placement={placement}
      title={() => (
        <div className={business_tooltip}>
          <span>
            {message || 'If you would like to submit a new campaign, please select a package.'}
          </span>
          <CustomButton size="small" background="blue">
            <Link to={"/packages"}>View Packages</Link>
          </CustomButton>
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default PackageTooltip;
