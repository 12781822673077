import { platformState, platformActionType, SAVE_ALL_PLATFORMS,SAVE_BUSINESS_PLATFORMS } from './types';

const initialState: platformState = {
  platforms: [],
  businessPlatforms:[]
};

export function PlatformsReducer(state = initialState, action: platformActionType): platformState {
  switch (action.type) {
    case SAVE_ALL_PLATFORMS:
      return {
        ...state,
        platforms: action.payload,
      };

    case SAVE_BUSINESS_PLATFORMS:
      return {
        ...state,
        businessPlatforms: action.payload,
      };

    default:
      return state;
  }
}
