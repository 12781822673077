import { packageState, packageActionType, SAVE_PACKAGES } from './types';

const initialState: packageState = {
  packages: [],
};

export function PackagesReducer(state = initialState, action: packageActionType): packageState {
  switch (action.type) {
    case SAVE_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };

    default:
      return state;
  }
}
