import styles from './BusinessSelection.module.css';
import { business, businesses } from 'src/redux/business/types';
import { Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { imageSrc } from 'src/utils';
import { Link, useParams } from 'react-router-dom';
import { getBusiness } from 'src/services/DataAction/Businesses';
import { useDispatch } from 'react-redux';
import { SaveCurrentBusiness } from 'src/redux/business/action';
import { useCallback, useMemo } from 'react';
import { getAllAgents } from 'src/services/DataAction/Agents';
import { SaveAgents } from 'src/redux/agents/action';
import { getAllCustomers } from 'src/services/DataAction/Customers';
import { SaveCustomers } from 'src/redux/customers/action';
import { getAllBusinessPlatforms } from 'src/services/DataAction/Platforms';
import { SaveBusinessPlatforms } from 'src/redux/platforms/action';
import { getAllBusinessCampaigns } from 'src/services/DataAction/Campaigns';
import { SaveCampaigns } from 'src/redux/campaigns/action';
import { v4 as uuidv4 } from 'uuid';

const {
  businessSelection_container,
  business_item,
  BusinessMenuDropDown,
  currentBusiness_title,
  createBusiness_btn,
} = styles;

interface propsType {
  businesses: businesses;
  currentBusiness: business | null;
}
function BusinessSelection({ businesses, currentBusiness }: propsType) {
  const dispatch = useDispatch();
  const { campaignId ,agentId , customerId}: any = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  
  const updateCurrentBusinessAllData = async (businessId: number) => {
    messageApi.open({
      key: 'updatable',
      type: 'loading',
      content: `Updating your data...`,
      duration:0
    });
    await getAllAgents(businessId).then((res) =>
      dispatch(SaveAgents(res.data?.results))
    );
    await getAllCustomers(businessId).then((res) =>
      dispatch(SaveCustomers(res.data?.results))
    );
    await getAllBusinessCampaigns(businessId).then((res) =>
      dispatch(SaveCampaigns(res.data?.results))
    );
    await getAllBusinessPlatforms(businessId).then((res) =>
      dispatch(SaveBusinessPlatforms(res.data?.results))
    );
  };

  const switchBusiness = useCallback(
    (businessId: number, businessName: string) => {
      messageApi.open({
        key: 'updatable',
        type: 'loading',
        content: `Switching to ${businessName}...`,
        duration:0
      });
      getBusiness(businessId)
        .then((res) => {
            dispatch(SaveCurrentBusiness(res.data));
            updateCurrentBusinessAllData(businessId).then(() =>{
              messageApi.open({
                key: 'updatable',
                type: 'success',
                content: `Your Business is switched to ${businessName} successfully.`,
                duration: 3,
              }).then(() =>{
                if(campaignId || customerId || agentId){
                  window.history.back();
                }
              });
              
            })
        })
        .catch((error) => {
          messageApi.open({
            key: 'updatable',
            type: 'error',
            content: `Switching to ${businessName} is failed! reason:${error?.response?.data?.message}`,
            duration: 4,
          });
        });
    },
    [agentId, campaignId, customerId]
  );

  const businessItems = useMemo(
    () =>
      businesses
        .map((business) => {
          return {
            label: (
              <div
                className={business_item}
                onClick={() => {
                  if (business.id === currentBusiness?.id) return;
                  switchBusiness(business.id, business.name);
                }}
              >
                {business.name}
                {business.id === currentBusiness?.id && (
                  <Link to="/business_information">
                    <img
                      src={imageSrc('editIcon', 'dashboard_icons')}
                      alt=""
                    />
                  </Link>
                )}
              </div>
            ),
            key: uuidv4(),
          };
        })
        .concat({
          label: (
              <Link
                to="/test_campaign"
                className={createBusiness_btn}
              >
                <img
                  src={imageSrc('plusSquare')}
                  alt="plus"
                />
                Create a new Business
              </Link>
          ),
          key: uuidv4(),
        }),
    [businesses, switchBusiness, currentBusiness]
  );

  return (
    <div className={businessSelection_container}>
      <span>Businesses</span>
      <Dropdown
        menu={{ items: businessItems }}
        className={BusinessMenuDropDown}
        trigger={['click']}
      >
        <div>
          <h4 className={currentBusiness_title}>{currentBusiness?.name}</h4>
          <DownOutlined />
        </div>
      </Dropdown>
      {contextHolder}
    </div>
  );
}

export default BusinessSelection;
