import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import styles from './DashboardLayout.module.css';
import { BreadCrumb } from '../../index';
import Sidebar from '../../Sidebar';
import DashboardHeader from '../../Header';
import Helmet from 'react-helmet';
import { getAllBusinesses } from 'src/services/DataAction/Businesses';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBusinesses, SaveCurrentBusiness } from 'src/redux/business/action';
import { getUserProfile } from 'src/services/DataAction/User';
import { SaveUser } from 'src/redux/users/action';
import { RootState } from 'src/redux/store';
import { getCreditCards } from 'src/services/DataAction/Billing';
import { SaveCreditCards } from 'src/redux/billing/action';
import { getAllBusinessCampaigns } from 'src/services/DataAction/Campaigns';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from 'react-responsive';
import { imageSrc } from 'src/utils';
import { sideBarItems } from 'src/routes';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const clearData = () => {
   localStorage.clear();
   sessionStorage.clear();
};

function isiOS() {
   return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
   );
}

type layoutElementType = {
   children: React.ReactNode;
   hasHeader: boolean;
   hasSidebar?: boolean;
   routeName: string;
};

const { content_container, mobileMenuContainer, isOpen, pwaSuggestionContainer, isSuggestionOpen } = styles;
const { Content } = Layout;

const DashboardLayout = ({ children, hasHeader = true, hasSidebar = false, routeName }: layoutElementType) => {
   const dispatch = useDispatch();
   const Navigate = useNavigate();
   const needsBreadcrumb = routeName !== 'Welcome' && routeName !== 'New Campaign' && routeName !== 'Packages' && routeName !== 'Success!' && routeName !== 'Test Campaign';
   const currentBusiness = useSelector((state: RootState) => state.businesses.currentBusiness);
   const [mobileMenu, setMobileMenu] = useState(false);
   const { logout } = useAuth0();
   const isNotDesktop = useMediaQuery({
      query: '(max-width: 1023px)',
   });
   const user = useSelector((state: RootState) => state.user.user);
   const hasPackage = !!user?.user_package?.cap;

   const saveBusinessInfo = async () => {
      getAllBusinesses()
         .then((res) => {
            const businesses = res?.data?.results;
            if (businesses?.length > 0 && window.location.pathname !== `/welcome`) {
               dispatch(SaveBusinesses(businesses));
               if (!currentBusiness) {
                  dispatch(SaveCurrentBusiness(businesses[0]));
               }
               if (businesses?.length === 1) {
                  getAllBusinessCampaigns(businesses[0]?.id).then((response) => {
                     const campaigns = response.data.results;
                     if (campaigns?.length === 0 && window.location.pathname !== `/test_campaign` && window.location.pathname !== `/welcome`) {
                        Navigate('/test_campaign', { state: businesses[0] });
                     }
                  });
               }
            } else if (businesses?.length === 0 && window.location.pathname !== `/test_campaign` && window.location.pathname !== `/welcome`) {
               dispatch(SaveBusinesses(businesses));
               dispatch(SaveCurrentBusiness(null));
               window.location.href = '/test_campaign';
            }
         })
         .catch((err) => {
            if (err.response.status === 403) {
               clearData();
               logout();
            }
         });
   };

   const saveProfileInfo = () => {
      getUserProfile().then((res) => {
         dispatch(SaveUser(res.data));
      });
   };

   const saveCreditCardInfo = () => {
      getCreditCards().then((res) => {
         const creditCards = res?.data?.results;
         dispatch(SaveCreditCards(creditCards));
      });
   };

   const hasToken = !!localStorage.getItem('upperly_token');
   const path = window.location.pathname;
   useEffect(() => {
      if (path.includes('/login') || !hasToken) return;
      saveBusinessInfo();
      saveProfileInfo();
      saveCreditCardInfo();
   }, [path, hasToken]);

   const [showPwaSuggestion, setShowPwaSuggestion] = useState(false);

   useEffect(() => {
      if (!window.matchMedia('(display-mode: standalone)').matches && isiOS() && sessionStorage.getItem('seenPwaSuggestion') !== 'true') {
         setShowPwaSuggestion(true);
      }
   }, []);

   return (
      <>
         <Helmet>
            <title>Upperly - {routeName}</title>
         </Helmet>

         {hasHeader && <DashboardHeader setMobileMenu={setMobileMenu} />}
         <Layout>{hasSidebar && <Sidebar />}</Layout>
         <Layout style={routeName !== 'Welcome' && routeName !== 'Packages' && routeName !== 'Test Campaign' && routeName !== 'New Campaign' && routeName !== 'Success!' && !isNotDesktop ? { marginLeft: 230 } : {}}>
            <Content className={routeName !== 'Welcome' ? content_container : ''} style={{ overflow: 'initial' }}>
               {needsBreadcrumb && <BreadCrumb />}
               {children}
            </Content>
         </Layout>
         <div className={`${mobileMenuContainer} ${mobileMenu ? isOpen : ''}`}>
            <button>
               <img src={imageSrc('close', undefined, 'svg')} alt='close' onClick={() => setMobileMenu(false)} />
            </button>

            {sideBarItems.map((link) => {
               return (
                  <NavLink to={link.path} key={link.id} onClick={() => setMobileMenu(false)} style={({ isActive }) => ({ color: isActive ? '#fff' : '#e4e4e4', fontWeight: isActive ? '600' : '400' })}>
                     {link.label}
                  </NavLink>
               );
            })}
            <div>
               <Link
                  to='/new_campaign'
                  onClick={(e) => {
                     if (!hasPackage) {
                        e.preventDefault();
                     }
                     setMobileMenu(false);
                  }}>
                  Create a New Campaign
               </Link>
            </div>
         </div>

         <div className={`${pwaSuggestionContainer} ${showPwaSuggestion ? isSuggestionOpen : ''}`}>
            <button>
               <img
                  src={imageSrc('close', undefined, 'svg')}
                  alt='close'
                  onClick={() => {
                     setShowPwaSuggestion(false);
                     sessionStorage.setItem('seenPwaSuggestion', 'true');
                  }}
               />
            </button>
            <div>
               <p>Use Upperly Anywhere,Anytime.</p>
               <p>Just easily add it to your Home screen</p>
               <ul>
                  <h5>To do so:</h5>
                  <li>
                     1. Click on <img src={imageSrc('iosArrow', undefined, 'svg')} alt='' /> in the tab bar
                  </li>
                  <li>
                     2. Select <img src={imageSrc('iosAdd', undefined, 'svg')} alt='' /> ‘Add to Home Screen’
                  </li>
               </ul>
            </div>
         </div>
      </>
   );
};

export default DashboardLayout;
