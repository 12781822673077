import React from "react";
import { Button, Col, Modal, Typography, Row, ModalProps } from "antd";
import styles from "./CustomModal.module.css";
import { imageSrc } from "src/utils";
import { DeleteOutlined } from "@ant-design/icons";
interface propsType {
  Paragraph?: string;
  deleteModal?: boolean;
  title?: React.ReactNode | string;
  centered?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  disabled: boolean;
  onClick?: () => void;
  addPlatformModal?: boolean;
  hasOnlyImage?: boolean;
}

const {
  modal,
  delete_btn,
  paragraph,
  btn_container,
  alert_container,
  alert_text_container,
  textArea_container,
  modalBtns,
  primary_btn,
  cancel_btn,
  centered_container,
} = styles;

const CustomModal = ({
  Paragraph,
  footer = null,
  open,
  onCancel,
  onOk,
  closable = false,
  okText,
  cancelText,
  title,
  deleteModal = false,
  centered = false,
  children,
  loading = false,
  disabled,
  addPlatformModal = false,
  hasOnlyImage = false,
  maskClosable = false,
  onClick,
}: propsType & ModalProps) => {
  return (
    <Modal
      maskClosable={maskClosable}
      cancelText={cancelText}
      okText={okText}
      // onOk={onOk}
      className={`${modal} ${centered ? centered_container : ""}`}
      closable={closable}
      keyboard
      centered={centered}
      mask
      onCancel={onCancel}
      open={open}
      footer={footer}
    >
      <Col span={24}>
        {title && <Typography.Title level={5}>{title}</Typography.Title>}
        {paragraph && (
          <Typography.Paragraph className={paragraph}>
            {Paragraph}
          </Typography.Paragraph>
        )}

        {children && <Row className={textArea_container}>{children}</Row>}
        <Row justify="center" className={btn_container}>
          {deleteModal && (
            <div className={alert_container}>
              <img src={imageSrc("warning", "business_icons")} alt="Alert" />
              <div className={alert_text_container}>
                <Typography.Title level={5}>Warning</Typography.Title>
                <Typography.Text>
                  All information will be deleted. This action is irreversible.
                </Typography.Text>
              </div>
            </div>
          )}

          {!hasOnlyImage && (
            <div className={modalBtns}>
              {addPlatformModal ? (
                <Button size="large" onClick={onClick} className={cancel_btn}>
                  {"Test"}
                </Button>
              ) : (
                <Button size="large" onClick={onCancel} className={cancel_btn}>
                  {cancelText}
                </Button>
              )}

              <Button
                loading={loading}
                disabled={disabled}
                size="large"
                onClick={onOk}
                className={deleteModal ? delete_btn : primary_btn}
                icon={deleteModal && <DeleteOutlined />}
              >
                {okText}
              </Button>
            </div>
          )}
        </Row>
      </Col>
    </Modal>
  );
};

export default CustomModal;
