import api from "src/services/AxiosConfig"
import {businessData} from "./types"
import axios from "axios";


export const getAllBusinesses = () => {
    return api.get(`/businesses/business/`)
}

export const getBusiness = (businessId : string | number) => {
    return api.get(`/businesses/business/${businessId}/`)
}

export const editBusiness = (businessId : string | number, data : businessData) => {
    return api.patch(`/businesses/business/${businessId}/`, data)
}

export const createBusiness = (data : businessData) => {
    return api.post(`/businesses/business/`, data)
}

export const getAllBusinessCategories = () => {
    return axios.get(`/static/panel/files/jsons/business_categories.json`)
}
