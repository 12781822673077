import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import store  from './redux/store';
import './styles/global.modules.css';
import App from './App';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from "@auth0/auth0-react";

if (process.env.NODE_ENV === 'production') {
  // Disable the error overlay in production
  const disableErrorOverlay = () => {
    const script = document.createElement('script');
    script.innerHTML = `
      window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__ = {
        supportsFiber: true,
        overrideHookState: () => {},
        overrideDispatcher: () => {}
      };
    `;
    document.body.appendChild(script);
  };
  disableErrorOverlay();
}

let persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <App />
      </Auth0Provider>
    </PersistGate>
  </Provider>
);
